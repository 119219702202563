<template>
    <div class="action-notice-inform-document">
        <div class="title f-bold" v-html="$translate('ACTION_NOTICE_CANCEL_FEE_REQUESTED')" />
        <div class="body">
            약속 상대방: {{ content.target_name + '(' + content.target_id + ')' }} <br />
            약속 날짜: {{ getMeetDate + '요일 ' + getMeetTime + '시' }} <br />
            수수료 종류: {{ content.penalty_product.name + '(' + content.penalty_product.selling_price / 10000 + '만원)' }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionRequestCancel',
    props: ['message'],
    computed: {
        getMeetDate() {
            return (
                this.$moment(this.content.date_confirmed).format('YY/MM/DD') +
                ' ' +
                ['일', '월', '화', '수', '목', '금', '토'][this.$moment(this.date).day()]
            )
        },
        getMeetTime() {
            return (
                (this.$moment(this.content.date_confirmed).format('A') === 'AM' ? '오전' : '오후') +
                ' ' +
                this.$moment(this.content.date_confirmed).format('h')
            )
        },
        content() {
            return this.$mustParse(this.message.content)
        }
    },
}
</script>
